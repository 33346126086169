<template>
  <div class="mt-5">
    <div v-if="spkData.header.jenis_spk === 'OTHER'" class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-primary con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            SPP dengan jenis OTHER tidak memiliki progress pekerjaan.
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="flex items-center space-x-3">
        <vs-button color="primary" size="small" type="border" icon-pack="feather" icon="icon-refresh-cw" class="px-3 mt-3" @click="getData">Refresh</vs-button>
      </div>
      <vs-progress class="" indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>
      <div class="overflow-auto">
        <vs-table :data="items" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Action</vs-th>
            <vs-th class="whitespace-no-wrap">ID</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl</vs-th>
            <vs-th class="whitespace-no-wrap">SPP Progress</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            <vs-th class="whitespace-no-wrap">Remark</vs-th>
            <vs-th class="whitespace-no-wrap">Foto</vs-th>
            <vs-th class="whitespace-no-wrap">Created At</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="flex items-center space-x-2">
                  <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                    <vs-button class="py-2 px-3" type="filled" size="small" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                    <vs-dropdown-menu>
                      <vs-dropdown-item class="p-1" @click="showModalPrintProgress(item)">Print</vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">#{{ item.id_progress_unit }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right font-bold text-success">{{ item.spk_progress_percent | idr }}%</vs-td>
              <vs-td class="">{{ item.keterangan }}</vs-td>
              <vs-td class="">{{ item.remark }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-image" class="px-2" v-if="item.list_foto_kontraktor.length > 0" @click="showImages(item.list_foto_kontraktor)">Lihat Foto</vs-button>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </template>

    <!--modals-->
    <ImageViewer ref="viewer"/>
    <SpkProgressPrint :isActive.sync="modalPrintProgress.active"
                    :idProgress="modalPrintProgress.idProgress"
                    :progressAchievedPercent="modalPrintProgress.progressAchievedPercent"
                    @done="onDoneModalPrintProgress"/>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import _ from 'lodash'

export default {
  name: 'TabProgress',
  props: {
    isActive: { type: Boolean }
  },
  components: {
    ImageViewer,
    SpkProgressPrint: () => import('@/views/pages/proyek/spk/tabs-show/progress/SpkProgressPrint')
  },
  mixins: [printoutMixin],
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.isFirstActive = false
        this.getData()
      }
    }
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    }
  },
  data () {
    return {
      modalPrintProgress: {
        active: false,
        idProgress: null,
        progressAchievedPercent: null
      },
      isFirstActive: true,
      isLoading: false,
      items: []
    }
  },
  methods: {
    getData () {
      this.isLoading = true

      const idSpk = this.$route.params.idSpk
      SpkRepository.getSpkProgress(idSpk)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showModalPrintProgress (progressItem) {
      this.modalPrintProgress.idProgress = progressItem.id_progress_unit
      this.modalPrintProgress.progressAchievedPercent = progressItem.spk_progress_percent
      this.modalPrintProgress.active = true
    },

    onDoneModalPrintProgress (data) {
      const idProgress = this.modalPrintProgress.idProgress
      const listIdTermin = data.listIdTermin
      this.printProgress(idProgress, listIdTermin)
    },

    printProgress (idProgress, listIdTermin) {
      this.showPrintOutFile('SPP_PROGRESS', {
        id_progress: idProgress,
        list_id_spk_d: listIdTermin
      })
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    }
  }
}
</script>
